// App.js
import Navbar from "./components/Navbar";
import "./components/Navbar.css";
import Home from "./components/pages/Home"


function App() {
  return (
    <div>
      <Navbar />
      <div className="container">
      <Home />
      </div>
    </div>
  );
}

export default App;