import React from 'react';
import '../App.css';
import './illustration.css';


function Illustration() {
  return (
    <section>
      <div class="container">
        <div class="left">
        </div>
          <div class="right">
            <div class="content">
              <h1>We're Under Construction</h1>
              <p>When we hear B2B, we think farm to restaurant. What goes around comes around, are you 
              keeping your capital within your community? 
              <br/>
              <br/>
              We're not ready just yet, but visit our site again in <u>May 2023</u> to explore how forRegulars can boost local engagement for your business. </p>
              <a href="https://www.forbes.com/sites/forbesfinancecouncil/2022/06/28/7-reasons-to-shop-local-and-support-small-businesses/?sh=7f0c6f2b50d1" class="btn" >Forbes:<p>Why Shop Small?</p></a>
            </div>
          </div>
        </div>
    </section>

);
}
export default Illustration;
    /* function Illustration() {
      return (
        <div class='container'>
            <div class='left'>
            <img src='/images/forRegPic1.PNG' alt="farmer looking at field" />
            </div>
          <div class='wrapper'>
            <div class='top'>
            <p> Give your business the edge with subscription based payments. It takes 5 minutes. </p>
            </div>
            <div class=''>
            <h1> Business Owner? <br></br>Find local small batch Suppliers </h1>
            </div>
            <div class='bottom'>
                <p>When we hear B2B, we think farm to restaurant. What goes around comes around, are you 
                keeping your capitol within your community? Learn about how forRegulars aims to boosts local spending in communities across the US </p>
            </div>
            
            </div>
        </div>
      );
    }
    */

