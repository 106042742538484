import React from 'react';
import '../../App.css';
import Illustration from '../illustration';

function Home() {
  return (
    <>
      <Illustration />
    </>
  );
}


export default Home;
